import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCjDeZFTDXN8lpUVnENLIHYFvLVQnNVrXw",
  authDomain: "kunchaaparivara.firebaseapp.com",
  projectId: "kunchaaparivara",
  storageBucket: "kunchaaparivara.appspot.com",
  messagingSenderId: "430478467259",
  appId: "1:430478467259:web:d146bb3a06de22aba0c99e"
};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
